import { Button, MenuItem, Select, Stack, Typography } from "@mui/material";
import BludataBluTitle from "../UI/BludataBluTitle";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import {
  setLinguaImpostata,
  setNuovaAnagrafica,
  setSelectedView,
} from "../store/slices/RootSlice";
import RichiestaConsensoBlock from "./checkFirma/RichiestaConsensoBlock";
import { useRef, useState } from "react";
import SignaturePad from "react-signature-pad-wrapper";
import { Box } from "@mui/system";
import { BluAsyncDialog } from "@bludata/components";
import BludataLoaderFullscreen from "../UI/BludataLoaderFullscreen";
import axios from "axios";
import { getIdCode } from "../App";
import linguaPrivacy from "../static/linguaPrivacy.json";
import italian from "../images/italian.png";
import english from "../images/english.png";
import german from "../images/german.png";

const getCodLingua = (lingua: string) => {
  if (lingua === "EN") return 1;
  if (lingua === "DE") return 2;
  return 0;
};

function RichiestaFirma() {
  const {
    infoCliente,
    linguaImpostata,
    clienteFirma,
    testiDefault,
    nuovaAnagrafica,
  } = useSelector((state: RootState) => state.root);
  const dispatch = useDispatch<AppDispatch>();
  const [consenso1, setConsenso1] = useState<boolean>();
  const [consenso2, setConsenso2] = useState<boolean>();
  const [consenso3, setConsenso3] = useState<boolean>();
  const [textPrivacyOpened, setTextPrivacyOpened] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const signCanvas = useRef() as React.MutableRefObject<any>;
  const linguaLW = linguaImpostata.toLowerCase();
  const { languageEnglish, languageItalian, languageGerman } = linguaPrivacy;

  let linguaggio = languageItalian;

  if (linguaImpostata === "EN") {
    linguaggio = languageEnglish;
  }
  if (linguaImpostata === "DE") {
    linguaggio = languageGerman;
  }

  let titoloPrivacy =
    infoCliente?.titolo_it || testiDefault?.privacyTitle || "";
  let testoConsenso1 =
    infoCliente?.textPrivacy1_it || testiDefault?.privacyTrattamentoText || "";
  let testoConsenso2 =
    infoCliente?.textPrivacy2_it || testiDefault?.privacyProfilazioneText || "";
  let testoConsenso3 =
    infoCliente?.textPrivacy3_it || testiDefault?.privacyMarketingText || "";
  let testoFinalita =
    infoCliente?.textFinalitaPrivacy_it ||
    testiDefault?.privacyFinalitaText ||
    "";

  if (linguaImpostata === "EN") {
    titoloPrivacy = infoCliente?.titolo_en || titoloPrivacy;
    testoConsenso1 = infoCliente?.textPrivacy1_en || testoConsenso1;
    testoConsenso2 = infoCliente?.textPrivacy2_en || testoConsenso2;
    testoConsenso3 = infoCliente?.textPrivacy3_en || testoConsenso2;
    testoFinalita = infoCliente?.textFinalitaPrivacy_en || testoFinalita;
  }

  if (linguaImpostata === "DE") {
    titoloPrivacy = infoCliente?.titolo_de || titoloPrivacy;
    testoConsenso1 = infoCliente?.textPrivacy1_de || testoConsenso1;
    testoConsenso2 = infoCliente?.textPrivacy2_de || testoConsenso2;
    testoConsenso3 = infoCliente?.textPrivacy3_de || testoConsenso2;
    testoFinalita = infoCliente?.textFinalitaPrivacy_de || testoFinalita;
  }

  const flagPrivacy1 = !testiDefault
    ? (infoCliente as any)[`flagPrivacy1_${linguaLW}`]
    : true;
  const flagPrivacy2 = !testiDefault
    ? (infoCliente as any)[`flagPrivacy2_${linguaLW}`]
    : true;
  const flagPrivacy3 = !testiDefault
    ? (infoCliente as any)[`flagPrivacy3_${linguaLW}`]
    : true;

  const onBackTap = () => {
    if (nuovaAnagrafica) {
      dispatch(setSelectedView("forminserimento"));
    } else {
      dispatch(setNuovaAnagrafica(null));
      dispatch(setSelectedView("home"));
    }
  };

  const onClearTap = () => {
    signCanvas.current.clear();
  };

  const onConfirmTap = () => {
    if (!consenso1) {
      BluAsyncDialog({
        title: "Attenzione",
        type: "warning",
        message:
          "Devi selezionare <b>Sì</b> almeno nella prima casella, altrimenti non possiamo trattare i tuoi dati.<br/><br/>Premi <b>Continua</b> e seleziona <b>Sì</b> almeno nella prima casella.",
        hideDeclineButton: true,
        confimButton: "Continua",
      });
      return;
    }
    if (signCanvas.current.isEmpty()) {
      BluAsyncDialog({
        title: "Non hai inserito la firma",
        type: "warning",
        message: "Premi <b>Continua</b> ed aggiungi la firma.",
        hideDeclineButton: true,
        confimButton: "Continua",
      });
      return;
    }

    const base64Firma = signCanvas.current.toDataURL("image/jpeg");
    let oggettoParams: any = {
      idCode: getIdCode(),
      codCliente: clienteFirma?.["Codice filiale"],
      Firma: base64Firma,
      consInf: consenso1,
      consMark: consenso2,
      consProf: consenso3,
      Lingua: getCodLingua(linguaImpostata),
    };
    let urlFirma = "/postwebclifirma";

    setLoading(true);

    if (nuovaAnagrafica) {
      oggettoParams = {
        idCode: getIdCode(),
        dati: JSON.stringify({
          ...nuovaAnagrafica,
          Firma: base64Firma,
          consInf: consenso1,
          consMark: consenso2,
          consProf: consenso3,
          Lingua: getCodLingua(linguaImpostata),
        }),
      };
      urlFirma = "/webcli";
    }

    axios
      .post(urlFirma, oggettoParams)
      .then(async () => {
        await BluAsyncDialog({
          title: linguaggio.swal.documentoFirmato,
          message: linguaggio.swal.documentoFirmatoSuccess,
          hideDeclineButton: true,
          confimButton: linguaggio.swal.continua,
          type: "info",
        });
        window.location.reload();
      })
      .catch(() => {
        BluAsyncDialog({
          title: linguaggio.erroreRete.titolo,
          message: linguaggio.erroreRete.testo,
          hideDeclineButton: true,
          confimButton: linguaggio.erroreRete.bottone,
          type: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Stack sx={{ p: 1 }} flexDirection="column" flex={1} spacing={2}>
      {loading && <BludataLoaderFullscreen open />}

      <Stack flex={1} sx={{ position: "relative" }}>
        <Stack
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflowY: "auto",
            p: 1,
          }}
          flexDirection="column"
          spacing={1}
        >
          <Stack flexDirection="row" gap={1}>
            <BludataBluTitle
              sx={{ flex: 1 }}
              text={infoCliente!["Ragione sociale"]}
            />
            {!nuovaAnagrafica &&
              (!!infoCliente?.["Nome privacy en"] ||
                !!infoCliente?.["Nome privacy de"]) && (
                <Select
                  value={linguaImpostata}
                  onChange={(e) => {
                    dispatch(setLinguaImpostata(e.target.value));
                  }}
                >
                  <MenuItem value={"IT"}>
                    <Stack flexDirection="row" gap={2} alignItems="center">
                      <img style={{ width: "24px" }} alt="ita" src={italian} />
                      <Typography sx={{ fontWeight: "bold" }}>
                        Italiano
                      </Typography>
                    </Stack>
                  </MenuItem>
                  {!!infoCliente?.["Nome privacy en"] && (
                    <MenuItem value={"EN"}>
                      <Stack flexDirection="row" gap={2} alignItems="center">
                        <img
                          style={{ width: "24px" }}
                          alt="english"
                          src={english}
                        />
                        <Typography sx={{ fontWeight: "bold" }}>
                          English
                        </Typography>
                      </Stack>
                    </MenuItem>
                  )}
                  {!!infoCliente?.["Nome privacy de"] && (
                    <MenuItem value={"DE"}>
                      <Stack flexDirection="row" gap={2} alignItems="center">
                        <img
                          style={{ width: "24px" }}
                          alt="english"
                          src={german}
                        />
                        <Typography sx={{ fontWeight: "bold" }}>
                          Deutsch
                        </Typography>
                      </Stack>
                    </MenuItem>
                  )}
                </Select>
              )}
          </Stack>
          <Stack flexDirection="row" gap={1} alignItems="start">
            <Stack flexDirection="column" flex={1}>
              <Typography>
                {infoCliente?.Indirizzo} {infoCliente?.Cap} {infoCliente?.Citta}{" "}
                ({infoCliente?.Provincia})
              </Typography>
              <Typography>{infoCliente?.Info1}</Typography>
              <Typography>{infoCliente?.Info2}</Typography>
            </Stack>
            <Stack flexDirection="column" flex={1}>
              <Typography>
                {clienteFirma?.Cognome} {clienteFirma?.Nome}{" "}
                {clienteFirma?.Cognome2}
              </Typography>
              <Typography>
                {clienteFirma?.Indirizzo} {clienteFirma?.Cap}{" "}
                {clienteFirma?.Citta} ({clienteFirma?.Provincia?.toUpperCase()})
              </Typography>
            </Stack>
          </Stack>
          <Typography
            component="div"
            sx={{
              "& p": {
                marginBlockStart: "3px",
                marginBlockEnd: "3px",
              },
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: titoloPrivacy || "" }} />
          </Typography>
          <Stack flexDirection="column">
            <Typography sx={{ fontWeight: "bold" }}>
              {linguaggio.GDPR.titoloTitolare}
            </Typography>
            <Typography>
              {!infoCliente?.["Titolare trattamento"]?.includes(
                linguaggio.GDPR.testoTitolare
              ) && linguaggio.GDPR.testoTitolare}{" "}
              {infoCliente?.["Titolare trattamento"]} email:{" "}
              {infoCliente?.["Privacy Email Titolare"]}
            </Typography>
          </Stack>
          <Box
            sx={
              textPrivacyOpened
                ? undefined
                : { minHeight: 150, maxHeight: 150, overflowY: "hidden" }
            }
          >
            <Typography
              component="div"
              sx={{
                "& p": {
                  marginBlockStart: "3px",
                  marginBlockEnd: "3px",
                },
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: testoFinalita || "" }} />
            </Typography>
          </Box>
          {!textPrivacyOpened && (
            <Stack alignItems="start">
              <Button
                variant="outlined"
                onClick={() => {
                  setTextPrivacyOpened(!textPrivacyOpened);
                }}
              >
                {linguaggio.GDPR.continuaALeggere}
              </Button>
            </Stack>
          )}
          <RichiestaConsensoBlock
            consensoValue={consenso1}
            language={linguaImpostata}
            show={flagPrivacy1}
            text={testoConsenso1}
            onConsensoChange={(consenso) => {
              setConsenso1(consenso);
            }}
          />
          <RichiestaConsensoBlock
            consensoValue={consenso2}
            language={linguaImpostata}
            show={flagPrivacy2}
            text={testoConsenso2}
            onConsensoChange={(consenso) => {
              setConsenso2(consenso);
            }}
          />
          <RichiestaConsensoBlock
            consensoValue={consenso3}
            language={linguaImpostata}
            show={flagPrivacy3}
            text={testoConsenso3}
            onConsensoChange={(consenso) => {
              setConsenso3(consenso);
            }}
          />
          <Box sx={{ height: 50 }} />
          <BludataBluTitle text={linguaggio.mainView.firma} />
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              sx={{
                border: "1px solid var(--divider-color)",
                borderRadius: "8px",
                width: 700,
              }}
            >
              <SignaturePad
                ref={signCanvas}
                options={{
                  minWidth: 0.5,
                  maxWidth: 2.5,
                  backgroundColor: "white",
                }}
              />
            </Box>
          </Stack>
        </Stack>
      </Stack>
      <Stack flexDirection="row" gap={1}>
        <Button
          variant="outlined"
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={onBackTap}
        >
          {linguaggio.GDPR.tastoIndietro}
        </Button>
        <Button
          onClick={onClearTap}
          color="error"
          variant="outlined"
          startIcon={<DeleteIcon />}
        >
          {linguaggio.GDPR.tastoAzzera}
        </Button>
        <Button
          onClick={onConfirmTap}
          disableElevation
          startIcon={<DoneIcon />}
        >
          {linguaggio.GDPR.tastoConferma}
        </Button>
      </Stack>
    </Stack>
  );
}

export default RichiestaFirma;
