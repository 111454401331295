import { LicenseInfo } from "@mui/x-license-pro";
import "./App.scss";
import { BluAsyncDialog, BluThemeProvider } from "@bludata/components";
import { isTablet, isIPad13, isDesktop } from "react-device-detect";
import DispositivoNonCompatibile from "./views/DispositivoNonCompatibile";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import BludataLoaderFullscreen from "./UI/BludataLoaderFullscreen";
import InfoClienteInterface from "./interfaces/InfoClienteInterface";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./store";
import { setInfoCliente, setTestiDefault } from "./store/slices/RootSlice";
import AppLayout from "./views/AppLayout";
import TestiDefaultInterface from "./interfaces/TestiDefaultInterface";
import BluSignAttivazioneCamera from "./views/BluSignAttivazioneCamera";

LicenseInfo.setLicenseKey(
  "aee9079aa5d0708e65ec1814624bd461Tz03MzcyNCxFPTE3MjUwMjU0MjQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

var checkIpadOs = function () {
  const ua = window.navigator.userAgent;

  if (ua.indexOf("Macintosh") > -1) {
    try {
      document.createEvent("TouchEvent");
      return true;
    } catch (e) {}
  }

  return false;
};

export const clearIpadBluSign = () => {
  localStorage.removeItem("codiceAzienda_ipad");
  localStorage.removeItem("bluSign_url_ipad");
};

export const isIpadOs = checkIpadOs();

export const getIdCode = () => localStorage.codiceAzienda_ipad;

const url = new URL(window.location.href);
const origin = url.origin;
export const isIlMioOttico =
  (localStorage.bluSign_url_ipad || "").indexOf("ilmioottico") >= 0;
//@ts-ignore
export const isMiniApp = window.navigator.standalone === true;
export const isDev = origin.indexOf("http://localhost") >= 0;

function App() {
  const piattaformaAbilitata = isIPad13 || isTablet || isIpadOs || isDesktop;
  const queryString = window.location.search;
  const oldDomain = url.searchParams.get("domain");
  const oldCode = url.searchParams.get("blusign_code");
  const fromOldBluSign = !!oldDomain && !!oldCode;
  if (fromOldBluSign) {
    localStorage.setItem("codiceAzienda_ipad", oldCode);
    localStorage.setItem("bluSign_url_ipad", oldDomain);
    window.location.href = window.location.origin;
  }
  const BluSignRegistrato = localStorage.hasOwnProperty("codiceAzienda_ipad");
  const urlParams = new URLSearchParams(queryString);
  const activationToken = urlParams.get("x");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { infoCliente, linguaImpostata } = useSelector(
    (state: RootState) => state.root
  );

  if (!fromOldBluSign && !localStorage.bluSign_url_ipad && !!getIdCode()) {
    localStorage.removeItem("codiceAzienda_ipad");
    window.location.reload();
  }

  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";

  axios.interceptors.request.use(
    function (config) {
      const urlBase = localStorage.bluSign_url_ipad;
      const urlToCall = `${urlBase}/anagrafica${config.url}`;
      const azureUrl = "/api/GetBluSignAnagraficaProxy";

      if (
        (config.url || "").indexOf("/") === 0 &&
        (config.url || "").indexOf("/api/") < 0
      ) {
        config.url = azureUrl;
        config.headers["Target-URL"] = urlToCall;
      }

      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  const callInfoCliente = useCallback(() => {
    const gestioneErrore = (message?: string) => {
      setLoading(false);

      BluAsyncDialog({
        title: "Attenzione",
        message:
          message ||
          "Errore di connessione al server durante la procedura di attivazione.",
        confimButton: "Riavvia",
        hideDeclineButton: true,
        type: "error",
        onConfirmClick: () => {
          window.location.reload();
        },
      });
    };

    function extractFilename(pathOrFilename: string) {
      // This regex matches the last part of a path after the last slash or backslash
      const regex = /[^\\/]+$/;
      const match = pathOrFilename.match(regex);
      return match ? match[0] : pathOrFilename;
    }

    if (piattaformaAbilitata && BluSignRegistrato) {
      setLoading(true);
      axios<InfoClienteInterface[]>({
        method: "post",
        url: "/info",
        data: {
          idcode: getIdCode(),
        },
      })
        .then((response) => {
          if (response.data[0]) {
            if (response.data[0].textPrivacy1_it) {
              setLoading(false);
              dispatch(setInfoCliente(response.data[0]));
            } else {
              const fileName = extractFilename(
                response.data[0]["Nome privacy"]
              );
              axios
                .get<TestiDefaultInterface>(
                  `${process.env.REACT_APP_API_URL}/api/GetBluSignTextDefault`,
                  {
                    params: {
                      privacyName: fileName,
                      privacyLanguage: linguaImpostata.toLowerCase(),
                    },
                  }
                )
                .then((responseDefault) => {
                  setLoading(false);
                  dispatch(setTestiDefault(responseDefault.data));
                  dispatch(setInfoCliente(response.data[0]));
                })
                .catch((e) => {
                  gestioneErrore(
                    `Errore durante la lettura del file consenso privacy.<br/><i>${e.response.data.errorMessage}</i>`
                  );
                });
            }
          } else {
            gestioneErrore(
              "Errore durante la lettura dei dati dell'azienda. (1)"
            );
          }
        })
        .catch(() => {
          gestioneErrore(
            "Errore durante la lettura dei dati dell'azienda. (2)"
          );
        });
    }
  }, [dispatch, piattaformaAbilitata, linguaImpostata, BluSignRegistrato]);

  useEffect(() => {
    if (activationToken && !BluSignRegistrato) {
      setLoading(true);

      axios({
        method: "post",
        url: "/init",
        data: {
          idcode: activationToken,
        },
      })
        .then((response) => {
          const codiceAttivazioneDefinitivo = response.data;

          if (typeof codiceAttivazioneDefinitivo === "string") {
            localStorage.codiceAzienda_ipad = codiceAttivazioneDefinitivo;
            setLoading(false);
            callInfoCliente();
          } else {
            setLoading(false);
            BluAsyncDialog({
              title: "Attenzione",
              message:
                "Errore di connessione al server durante la procedura di attivazione.",
              confimButton: "Riavvia",
              hideDeclineButton: true,
              type: "error",
              onConfirmClick: () => {
                window.location.reload();
              },
            });
          }
        })
        .catch(() => {
          setLoading(false);

          BluAsyncDialog({
            title: "Attenzione",
            message:
              "Errore di connessione al server durante la procedura di attivazione.",
            confimButton: "Riavvia",
            hideDeclineButton: true,
            type: "error",
            onConfirmClick: () => {
              window.location.reload();
            },
          });
        });
    } else if (BluSignRegistrato) {
      callInfoCliente();
    }
  }, [activationToken, BluSignRegistrato, callInfoCliente]);

  if (!piattaformaAbilitata) {
    return <DispositivoNonCompatibile />;
  }

  if (!activationToken && !BluSignRegistrato) {
    clearIpadBluSign();
    return <BluSignAttivazioneCamera />;
  }

  return (
    <BluThemeProvider themeMode={"light"}>
      {loading && <BludataLoaderFullscreen open />}
      {!loading && infoCliente && <AppLayout />}
    </BluThemeProvider>
  );
}

export default App;
